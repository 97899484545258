import type { ReactElement } from 'react';

import { ScreenReaderOnly } from '@capsule/consumer-design-system';

import { SectionContainer } from '../../capsule-components/Sections/SectionContainer';
import { Col, Hidden, Row, Visible } from '../../capsule-elements/Grid';
import { MediumLink } from '../../capsule-elements/Link';
import { Typography } from '../../capsule-elements/Typography';
import { getUrlPathWithPreservedQuery } from '../../utils/utils';

import { clickIcon, clockIcon, refillIcon } from './icons';
import {
  ContentWrapper,
  IconSVG,
  IconWrapper,
  InlineBlockContent,
  RowBreak,
  RowContainer,
  StepDescription,
  StepTitle,
  Title,
  TitleContent,
  TitleLineTwo,
} from './styles';

type HowItWorksContent = {
  title: string | ReactElement;
  description: string | ReactElement;
  iconSrc: string;
};

const howCapsuleWorksContent: HowItWorksContent[] = [
  {
    title: 'Sign up for Capsule',
    description: (
      <>
        We’ll coordinate with your old pharmacy or doctor to get{' '}
        <InlineBlockContent>your prescriptions.</InlineBlockContent>
      </>
    ),
    iconSrc: clickIcon,
  },
  {
    title: (
      <>
        Schedule your free <br />
        <InlineBlockContent>same-day delivery</InlineBlockContent>
      </>
    ),
    description: (
      <>
        We accept your insurance and deliver your prescriptions at a time that{' '}
        <InlineBlockContent>works for you.</InlineBlockContent>
      </>
    ),
    iconSrc: clockIcon,
  },
  {
    title: (
      <>
        We manage{' '}
        <Hidden xs>
          <br />
        </Hidden>
        <InlineBlockContent>your refills</InlineBlockContent>
      </>
    ),
    description: (
      <>
        We’ll coordinate with your doctor and text you when it’s{' '}
        <InlineBlockContent>time for refills.</InlineBlockContent>
      </>
    ),
    iconSrc: refillIcon,
  },
];

const getHowCapsuleWorksContent = (content: HowItWorksContent[]) => {
  return (
    <RowContainer>
      <Row>
        {content.map((section, idx) => {
          return (
            <Col key={idx} xs={12} sm={4}>
              <IconWrapper>
                <IconSVG src={section.iconSrc} />
              </IconWrapper>
              <StepTitle variant="h3" marginBottom={'s3'}>
                {section.title}
              </StepTitle>
              <StepDescription variant="body">{section.description}</StepDescription>
              {idx !== content.length - 1 && (
                <Visible xs>
                  <RowBreak />
                </Visible>
              )}
            </Col>
          );
        })}
      </Row>
    </RowContainer>
  );
};

export const HowCapsuleWorksSection = () => {
  return (
    <SectionContainer backgroundColor={'capsuleGreen00'}>
      <ContentWrapper>
        <TitleContent>
          <Title>
            <Typography variant={'displayXL'} as={'h1'} smallDownVariant={'h1'}>
              How <TitleLineTwo>Capsule works</TitleLineTwo>
            </Typography>
          </Title>
          <Hidden sm xs>
            <MediumLink color={'capsuleGreen80'} href={getUrlPathWithPreservedQuery('/how-it-works')}>
              Learn More
              <ScreenReaderOnly>About How Capsule Works</ScreenReaderOnly>
            </MediumLink>
          </Hidden>
        </TitleContent>
        {getHowCapsuleWorksContent(howCapsuleWorksContent)}
      </ContentWrapper>
    </SectionContainer>
  );
};
