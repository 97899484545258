import styled from 'styled-components';

export const FaqContainer = styled.div`
  color: ${props => props.theme.colors.capsuleBlue50};
  margin-top: ${props => props.theme.space.s9};
  margin-bottom: ${props => props.theme.space.s9};
  ${props => props.theme.mediaQueries.smallDown} {
    margin-top: ${props => props.theme.space.s8};
    margin-bottom: ${props => props.theme.space.s8};
  }
`;
