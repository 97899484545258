import { Button } from '@capsule/consumer-design-system';

import { SectionContainer } from '../../capsule-components/Sections/SectionContainer';
import { HeroContainer } from '../../capsule-components/Sections/styles';
import { onGetStartedClickHandleRedirect } from '../../common/handlers/ClickHandlers';
import { BodyWrapper, ButtonWrapper, HeaderText, TextContent } from '../../common/styles/commonStyles';

import {
  HomeBodyText,
  TealPill,
  TealPillWrapper,
  TrianglePill,
  TrianglePillWrapper,
  YellowPill,
  YellowPillWrapper,
} from './styles';

export const Hero = () => {
  const isHomepageBannerShown = false; // Placeholder til we migrate the flag to Amplitude.

  return (
    <SectionContainer backgroundColor="capsuleBlue50" hasPadding={false} isOffsetForHeader={true}>
      <HeroContainer mobileHeight={'medium'} showHomepageBanner={isHomepageBannerShown}>
        <YellowPillWrapper>
          <YellowPill iconName="TallPill" />
        </YellowPillWrapper>
        <TealPillWrapper>
          <TealPill iconName="CirclePill" />
        </TealPillWrapper>
        <TrianglePillWrapper>
          <TrianglePill iconName="TrianglePill" />
        </TrianglePillWrapper>
        <TextContent>
          <HeaderText variant="displayXXXL" color="white">
            Meds
            <br />
            made easy
          </HeaderText>
          <BodyWrapper>
            <HomeBodyText variant="h3" color="white">
              Capsule is the pharmacy that delivers your{' '}
              <span>prescriptions, the same day, for free—all from your phone.</span>
            </HomeBodyText>
          </BodyWrapper>

          <>
            <ButtonWrapper>
              <Button
                variant="contained-primary-lg"
                isFullWidth
                onClick={() => onGetStartedClickHandleRedirect('Header CTA')}
              >
                Get Started
              </Button>
            </ButtonWrapper>
          </>
        </TextContent>
      </HeroContainer>
    </SectionContainer>
  );
};
