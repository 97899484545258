import { SectionContainer } from '../../capsule-components/Sections/SectionContainer';
import { PricingSectionContainer } from '../PricingSection/styles';

import { CNBC, CNN, Forbes, GMA, NYT, PillTall, WSJ } from './icons';
import {
  AsSeenInContent,
  CNBCPressIcon,
  CNNPressIcon,
  ContentWrapper,
  ForbesPressIcon,
  GMAPressIcon,
  NYTPressIcon,
  PressIconGroup,
  Quote,
  QuoteAuthor,
  QuoteContent,
  SmallCallout,
  Underline,
  WSJPressIcon,
  YellowPill,
} from './styles';

export const PressSection = () => {
  return (
    <PricingSectionContainer>
      <YellowPill src={PillTall} />
      <SectionContainer>
        <ContentWrapper>
          <AsSeenInContent>
            <SmallCallout variant="calloutSmall">As Seen In</SmallCallout>
            <Underline />
          </AsSeenInContent>
          <QuoteContent>
            <Quote variant="quote">
              “Designed to make it easier for patients to fill prescriptions and stay on their medications”
            </Quote>
            <QuoteAuthor variant="body" color="capsuleGray50" marginTop={'s3'}>
              &#8212; The Wall Street Journal
            </QuoteAuthor>
          </QuoteContent>
          <PressIconGroup>
            <GMAPressIcon src={GMA} />
            <CNBCPressIcon src={CNBC} />
            <ForbesPressIcon src={Forbes} />
            <WSJPressIcon src={WSJ} />
            <CNNPressIcon src={CNN} />
            <NYTPressIcon src={NYT} />
          </PressIconGroup>
        </ContentWrapper>
      </SectionContainer>
    </PricingSectionContainer>
  );
};
