import { useEffect } from 'react';

import { Layout } from '../capsule-components/Layout';
import { LogoTheme, NavTheme } from '../capsule-components/Layout/Header/constants';
import { SectionBorderDivider } from '../capsule-components/Sections/SectionBorderDivider';
import { Hidden } from '../capsule-elements/Grid';
import { EVENT, track } from '../capsule-tracking/analytics';
import { StickyButton } from '../common/StickyButton/StickyButton';

import { FAQ } from './FAQ';
import { Hero } from './Hero/Hero';
import { HowCapsuleWorksSection } from './HowCapsuleWorksSection';
import { PressSection } from './PressSection';
import { PricingSection } from './PricingSection';
import { ReviewSlider } from './ReviewSlider';
import { WhyCapsuleSection } from './WhyCapsuleSection';
import { ZipCodeSection } from './ZipCodeSection';

export const Home = () => {
  useEffect(() => {
    track(EVENT.viewHome);
  }, []);

  return (
    <Layout logoTheme={LogoTheme.white} maxPageWidth={false} navTheme={NavTheme.transparent} navOpacity={1}>
      <Hero />
      <div id="stickyanchor" />
      <StickyButton />
      <>
        <ZipCodeSection />
        <Hidden xs sm>
          <SectionBorderDivider />
        </Hidden>
      </>
      <WhyCapsuleSection />
      <HowCapsuleWorksSection />
      <PricingSection />
      <SectionBorderDivider />
      <ReviewSlider />
      <PressSection />
      <SectionBorderDivider />
      <FAQ />
    </Layout>
  );
};
