import axios from 'axios';

const headers = {
  'Content-Type': 'application/vnd.api+json',
  'Capsule-Client': 'web',
  'Capsule-Client-Version': '1.0.0',
  // eslint-disable-next-line new-cap
  tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const axiosConfig = {
  contentType: 'application/vnd.api+json',
  accept: 'application/vnd.api+json',
  headers,
  timeout: 5 * 60 * 1000, // 5 minutes time-out, let the server time out, but slow connections can continue
};

const client = axios.create({ ...axiosConfig, baseURL: process.env.NEXT_PUBLIC_BFF_ENDPOINT });
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.errorCode === 666) {
      window.location.replace('/maintenance');
      return;
    }
    return Promise.reject(error);
  }
);

export const checkZipCodeEligibility = (zipCode: string) => client.get(`api/delivery_availability/zip_code/${zipCode}`);
