import styled, { css } from 'styled-components';

export const FixedNav = styled.div<{ opacity: number }>`
  display: fixed;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.space.s5};
  z-index: 999;
  opacity: ${props => props.opacity};
  ${props =>
    props.opacity === 0 &&
    css`
      pointer-events: none;
    `}
  background: rgba(255, 255, 255, 0.76);
  /* Note: backdrop-filter has minimal browser support */
  backdrop-filter: blur(32px);
  ${props => props.theme.mediaQueries.small} {
    display: none;
  }
`;
