import { SectionContainer } from 'src/landing-pages/capsule-components/Sections/SectionContainer';
import { Hidden } from 'src/landing-pages/capsule-elements/Grid';

import {
  Content,
  EquationCallout,
  EquationWrapper,
  IconWrapper,
  PillTriangleSVG,
  PricingSectionContainer,
  RedHeart,
  SectionTitle,
  Sign,
  TitleContainer,
  TitleContent,
} from './styles';

export const PricingSection = () => {
  return (
    <PricingSectionContainer>
      <PillTriangleSVG iconName="PillTriangle" />
      <SectionContainer backgroundColor="white">
        <Content>
          <TitleContent>
            <TitleContainer>
              <SectionTitle variant="displayXL" smallDownVariant="h1">
                Capsule is free. Just pay your copay.
              </SectionTitle>
            </TitleContainer>
          </TitleContent>

          <EquationWrapper>
            <EquationCallout>
              Free, same-day{' '}
              <Hidden xs sm md lg>
                <br />
              </Hidden>
              delivery
            </EquationCallout>

            <Sign>+</Sign>

            <EquationCallout>
              Your usual{' '}
              <Hidden xs sm md lg>
                <br />
              </Hidden>
              insurance copay
            </EquationCallout>
            <Sign>=</Sign>
            <EquationCallout color="capsuleRed">
              A pharmacy you&#8217;ll love,{' '}
              <Hidden sm md lg>
                <br />
              </Hidden>{' '}
              at no extra cost{' '}
              <IconWrapper>
                <RedHeart iconName="RedHeart" />
              </IconWrapper>
            </EquationCallout>
          </EquationWrapper>
        </Content>
      </SectionContainer>
    </PricingSectionContainer>
  );
};
