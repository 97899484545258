import { useMediaQuery } from '@react-hookz/web';

import { SectionContainer } from 'src/landing-pages/capsule-components/Sections/SectionContainer';
import { Typography } from 'src/landing-pages/capsule-elements/Typography';
import { Breakpoint } from 'src/styles/theme';

import {
  ContentItem,
  ContentWrapper,
  FlexContent,
  FlexItem,
  Image,
  ImageWrapper,
  SectionTitle,
  TextWrapper,
} from './styles';

export const WhyCapsuleSection = () => {
  const mdDown = useMediaQuery(Breakpoint.mediumDown);

  return (
    <SectionContainer>
      <ContentWrapper>
        <SectionTitle variant="displayXL" smallDownVariant="h1" marginBottom={'s7'}>
          Experience a better pharmacy
        </SectionTitle>
        <FlexContent>
          <FlexItem>
            <ContentItem>
              <ImageWrapper>
                <Image
                  src={mdDown ? '/public/HandAndPills-Mobile.webp' : '/public/HandAndPills.webp'}
                  alt="Capsule Pill Bottle"
                />
              </ImageWrapper>
              <TextWrapper>
                <Typography variant="h3" smallDownVariant="h4">
                  Free, same-day prescription delivery
                </Typography>
              </TextWrapper>
            </ContentItem>
          </FlexItem>
          <FlexItem>
            <ContentItem>
              <ImageWrapper>
                <Image src={mdDown ? '/public/Sonia-Mobile.webp' : '/public/Sonia.webp'} alt="Capsule Pharmacist" />
              </ImageWrapper>
              <TextWrapper>
                <Typography variant="h3" smallDownVariant="h4">
                  Privately chat or text with our expert pharmacists
                </Typography>
              </TextWrapper>
            </ContentItem>
          </FlexItem>
          <FlexItem>
            <ContentItem>
              <ImageWrapper>
                <Image
                  src={mdDown ? '/public/WhyCapsule3-Mobile.webp' : '/public/WhyCapsule3.webp'}
                  alt="Capsule App"
                />
              </ImageWrapper>
              <TextWrapper>
                <Typography variant="h3" smallDownVariant="h4">
                  Get and manage all your medications from your phone
                </Typography>
              </TextWrapper>
            </ContentItem>
          </FlexItem>
        </FlexContent>
      </ContentWrapper>
    </SectionContainer>
  );
};
