import styled from 'styled-components';

import { BodyText } from '../../common/styles/commonStyles';
import { CapsuleIcon } from '../../icons/CapsuleIcon';

export const HomeBodyText = styled(BodyText)`
  color: #b1cae5 !important;
`;

export const YellowPill = styled(CapsuleIcon)`
  svg {
    width: 329px !important;
    height: 834px !important;
    ${props => props.theme.mediaQueries.largeDown} {
      width: 171px !important;
      height: 435px !important;
    }
    * {
      fill: #ffd300;
    }
  }
  ${props => props.theme.mediaQueries.smallDown} {
    svg {
      width: 171px;
      height: 435px;
    }
  }
`;

export const YellowPillWrapper = styled.div`
  position: absolute;
  bottom: -57rem;
  right: 26rem;

  ${props => props.theme.mediaQueries.largeDown} {
    left: 0;
    bottom: -28rem;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    left: -50px;
    bottom: -325px;
  }
`;

export const TealPill = styled(CapsuleIcon)`
  svg {
    width: 135%;
    height: 135%;
    transform: rotate(110deg);

    * {
      fill: #74a6a2;
    }
  }

  ${props => props.theme.mediaQueries.extraLargeDown} {
    svg {
      width: 338px;
      height: 338px;
    }
  }
`;

export const TealPillWrapper = styled.div`
  position: absolute;
  bottom: -18rem;
  right: -50px;

  ${props => props.theme.mediaQueries.largeDown} {
    right: -200px;
    bottom: 226px;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    right: -277px;
  }
`;

export const TrianglePillWrapper = styled.div`
  position: absolute;

  bottom: 35px;
  right: -400px;

  @media screen and (max-width: 1300px) {
    bottom: 150px;
    right: -300px;
  }

  ${props => props.theme.mediaQueries.largeDown} {
    bottom: -90px;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    right: -115px;
    bottom: -70px;
  }
`;

export const TrianglePill = styled(CapsuleIcon)`
  svg {
    transform: rotate(45deg);
    width: 388px;
    height: 400px;
    * {
      fill: #ef4931;
    }
  }

  ${props => props.theme.mediaQueries.smallDown} {
    svg {
      width: 202px;
      height: 181px;
    }
  }
`;
