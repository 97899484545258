import styled, { css } from 'styled-components';

import { Typography } from '../../capsule-elements/Typography';
import { CapsuleIcon } from '../../icons/CapsuleIcon';

const explicitFontSizeAndLineHeight = css`
  line-height: ${props => props.theme.lineHeights.headerTwo} !important;
  font-size: ${props => props.theme.fontSizes.headerTwo} !important;
  ${props => props.theme.mediaQueries.small} {
    line-height: 4rem !important;
    font-size: 3.3rem !important;
  }
  ${props => props.theme.mediaQueries.extraLarge} {
    line-height: 4.6rem !important;
    font-size: 3.8rem !important;
  }
`;

export const PricingSectionContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const EquationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.theme.mediaQueries.extraLargeDown} {
    display: block;
  }
  margin-bottom: ${props => props.theme.space.s7};
`;

export const EquationCallout = styled(props => <Typography variant="callout" {...props} />)`
  text-transform: none !important;
  font-weight: 400 !important;
  ${explicitFontSizeAndLineHeight}
  white-space: nowrap;
  margin-bottom: 0px !important;
  ${props => props.theme.mediaQueries.extraLargeDown} {
    white-space: normal;
    margin: ${props => props.theme.space.s5} 0px !important;
  }
  ${props => props.theme.mediaQueries.smallDown} {
    margin: ${props => props.theme.space.s1} 0px !important;
  }
`;

export const Content = styled.div`
  padding-bottom: ${props => props.theme.space.s7};
  padding-top: ${props => props.theme.space.s9};
  position: relative;
  ${props => props.theme.mediaQueries.smallDown} {
    padding-top: ${props => props.theme.space.s8};
    padding-bottom: ${props => props.theme.space.s8};
    width: 100%;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space.s9};
  ${props => props.theme.mediaQueries.extraLargeDown} {
    margin-bottom: ${props => props.theme.space.s7};
  }
`;

export const SectionTitle = styled(Typography)`
  padding: 0;
  ${props => props.theme.mediaQueries.extraLargeDown} {
    width: 100%;
  }
`;

export const TitleContainer = styled.div``;

export const Sign = styled.p`
  font-weight: 700 !important;
  margin: ${props => props.theme.space.s5} !important;
  ${explicitFontSizeAndLineHeight}
  color: ${props => props.theme.colors.capsuleBlue50};
  ${props => props.theme.mediaQueries.extraLargeDown} {
    margin: ${props => props.theme.space.s5} 0rem !important;
  }
  ${props => props.theme.mediaQueries.smallDown} {
    margin: ${props => props.theme.space.s1} 0rem !important;
  }
`;

export const IconWrapper = styled.span`
  margin: ${props => props.theme.space.s1};
  display: inline;

  ${props => props.theme.mediaQueries.extraLargeDown} {
    margin: ${props => props.theme.space.s5} 0rem;
    display: block;
  }
  ${props => props.theme.mediaQueries.smallDown} {
    margin: ${props => props.theme.space.s1};
  }
`;

export const RedHeart = styled(CapsuleIcon)`
  & > svg {
    width: 15px !important;
    height: 14px !important;
  }
  ${props => props.theme.mediaQueries.extraLargeDown} {
    & > svg {
      width: 15px !important;
      height: 14px !important;
    }
  }

  ${props => props.theme.mediaQueries.small} {
    & > svg {
      width: 27px !important;
      height: 25px !important;
    }
  }
`;

export const PillTriangleSVG = styled(CapsuleIcon)`
  & > svg {
    width: 69px !important;
    height: 62px !important;

    position: absolute;
    top: -22px;
    right: -22px;
    bottom: initial;
    * {
      fill: ${props => props.theme.colors.capsuleGreen55};
    }
  }
  ${props => props.theme.mediaQueries.extraLargeDown} {
    & > svg {
      width: 69px !important;
      height: 62px !important;
    }
  }
  ${props => props.theme.mediaQueries.small} {
    & > svg {
      width: 281px !important;
      height: 251px !important;
      position: absolute;
      right: -150px;
      top: initial;
      bottom: 0;
      z-index: 0;
    }
  }
`;
