import styled from 'styled-components';

export const HeroContainer = styled.div<{ showHomepageBanner: boolean; mobileHeight: string }>`
  height: 49.6rem;
  overflow: visible;
  position: relative;
  margin-top: ${props => (props.showHomepageBanner ? props.theme.space.s9 : 0)};

  ${props => props.theme.mediaQueries.small} {
    margin-top: ${props => (props.showHomepageBanner ? props.theme.space.s9 : props.theme.space.s5)};
  }

  ${props => props.theme.mediaQueries.smallDown} {
    height: ${props => (props.mobileHeight === 'large' ? '55.2rem' : '46.4rem')};
  }
`;

export const SectionBorder = styled.hr`
  border: none;
  border-top: 1px solid ${props => props.theme.colors.capsuleGray10};
`;
