import type { MouseEvent } from 'react';
import { useEffect } from 'react';

import { ScreenReaderOnly } from '@capsule/consumer-design-system';

import { useInView } from 'react-intersection-observer';

import { SectionContainer } from '../../capsule-components/Sections/SectionContainer';
import { Accordion } from '../../capsule-elements/Accordion/Accordion';
import { MediumLink } from '../../capsule-elements/Link';
import { Typography } from '../../capsule-elements/Typography';
import { EVENT, track } from '../../capsule-tracking/analytics';
import { HelpEmailLink } from '../../common/HelpEmail';
import { HelpPhoneLink } from '../../common/HelpPhone';
import { Link } from '../../common/styles/commonStyles';
import { getUrlPathWithPreservedQuery } from '../../utils/utils';

import { FaqContainer } from './styles';

const generateQuestionContent = () => {
  const handleLinkClick = (e: MouseEvent) => e.stopPropagation();

  return [
    {
      question: (
        <Typography variant="h2" as={'h2'} smallDownVariant={'h4'}>
          How much does it cost?
        </Typography>
      ),
      answer: (
        <Typography variant="body">Capsule is free to use. You only pay your copay on your medication.</Typography>
      ),
    },
    {
      question: (
        <Typography variant="h2" as={'h2'} smallDownVariant={'h4'}>
          Do you take my insurance?
        </Typography>
      ),
      answer: (
        <Typography variant="body">
          Yes. Capsule accepts all commercial insurance plans, Managed Medicaid, and Medicare Part D. We'll also
          coordinate directly with your insurance so you don't have to.
        </Typography>
      ),
    },
    {
      question: (
        <Typography variant="h2" as={'h2'} smallDownVariant={'h4'}>
          Does Capsule deliver to me?
        </Typography>
      ),
      answer: (
        <Typography variant="body">
          Find out{' '}
          <Link href={getUrlPathWithPreservedQuery('/get-started')} onClick={handleLinkClick}>
            <ScreenReaderOnly>by going </ScreenReaderOnly>here
            <ScreenReaderOnly> and seeing our list of available locations!</ScreenReaderOnly>
          </Link>
          .
        </Typography>
      ),
    },
    {
      question: (
        <Typography variant="h2" as={'h2'} smallDownVariant={'h4'}>
          Can all my doctors use Capsule?
        </Typography>
      ),
      answer: <Typography variant="body">Yes. Any doctor can send a prescription to Capsule.</Typography>,
    },
    {
      question: (
        <Typography variant="h2" as={'h2'} smallDownVariant={'h4'}>
          I still have more questions.
        </Typography>
      ),
      answer: (
        <Typography variant="body">
          Text us at <HelpPhoneLink type={'sms'} onClick={handleLinkClick} /> or email{' '}
          <HelpEmailLink onClick={handleLinkClick} />
        </Typography>
      ),
    },
  ];
};

export const FAQ = () => {
  const { inView, ref } = useInView({ threshold: 0.25 });

  const handleExpand = (isExpanded: boolean, text: string) => {
    // Fire track pre expansion where isExpanded is still false
    if (!isExpanded) {
      track(EVENT.expandHomepageFaq, { text });
    }
  };

  useEffect(() => {
    if (inView) {
      track(EVENT.viewFAQSection, { source: 'Home page' });
    }
  }, [inView]);

  const questionContent = generateQuestionContent();

  return (
    <SectionContainer>
      <FaqContainer ref={ref}>
        <Typography variant={'displayXL'} smallDownVariant={'h1'} as={'h1'} marginBottom={'s5'}>
          Still have questions?
        </Typography>
        <Accordion
          marginBottom={'s7'}
          questions={questionContent}
          hasInitialBorderTop={false}
          onExpand={handleExpand}
        />
        <MediumLink href={getUrlPathWithPreservedQuery('/questions')}>See More Questions</MediumLink>
      </FaqContainer>
    </SectionContainer>
  );
};
