import styled, { css, keyframes } from 'styled-components';

import { CapsuleIcon } from '../../icons/CapsuleIcon';

const ANIMATION_DURATION = '750ms';

const slideRight = keyframes`
  0% {
    transform: translateX(0);
  }
/* The width of the Tiles plus the margin is 35.1rem */
  100% {
    transform: translateX(35.1rem);
  }
`;

const slideLeft = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-35.1rem);
  }
`;

const mobileSlideRight = keyframes`
  0% {
    transform: translateX(0);
  }
/* The width of the Tiles plus the margin is 35.1rem */
  100% {
    transform: translateX(33.5rem);
  }
`;

const mobileSlideLeft = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-33.5rem);
  }
`;

const FADE_IN = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

export const PaddingContainer = styled.div`
  ${props => props.theme.mediaQueries.extraLargeDown} {
    padding-right: 12.8rem;
    padding-left: 12.8rem;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    padding-right: 0rem;
    padding-left: 0rem;
  }
`;
export const ReviewSectionContainer = styled.div`
  margin-top: ${props => props.theme.space.s9};
  margin-bottom: ${props => props.theme.space.s9};

  ${props => props.theme.mediaQueries.mediumDown} {
    margin-top: ${props => props.theme.space.s8};
    margin-bottom: ${props => props.theme.space.s8};
  }
`;

export const RatingContainer = styled.div<{ isFirst?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => (props.isFirst ? '6.4rem' : '0')} ${props => props.theme.space.s5} 0;
  position: relative;

  ${props => props.theme.mediaQueries.small} {
    padding: ${props => (props.isFirst ? props.theme.space.s9 : 0)} 12.8rem 0;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1130px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 8.4rem;

  ${props => props.theme.mediaQueries.smallDown} {
    padding-bottom: 3.2rem;
  }
`;

export const StarContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => props.theme.space.s3};
`;

export const GoldStar = styled(CapsuleIcon)`
  svg {
    width: 7.2rem;
    height: 7.2rem;
  }

  &:not(:first-child) {
    margin-left: 2rem;
    ${props => props.theme.mediaQueries.smallDown} {
      margin-left: ${props => props.theme.space.s2};
    }
  }

  ${props => props.theme.mediaQueries.smallDown} {
    svg {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
`;

export const Rating = styled.div`
  align-self: center;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: bold;
  font-size: 8rem;
  line-height: 8.8rem;
  color: ${props => props.theme.colors.capsuleBlue50};
  margin-bottom: ${props => props.theme.space.s5};

  ${props => props.theme.mediaQueries.mediumDown} {
    margin-bottom: ${props => props.theme.space.s2};
    font-size: 4.4rem;
    line-height: 5.2rem;
  }
`;

export const RatingSources = styled.div`
  align-self: center;
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.headerFour};
  font-weight: ${props => props.theme.fontWeights.book};
  line-height: ${props => props.theme.lineHeights.headerFour};
  color: ${props => props.theme.colors.capsuleBlue50};
  margin-bottom: 7.2rem;

  ${props => props.theme.mediaQueries.mediumDown} {
    margin-bottom: 3.2rem;
  }
`;

export const TilesContainer = styled.div<{ ['data-testid']?: string }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${props => props.theme.space.s7};
  justify-content: center;

  ${props => props.theme.mediaQueries.mediumDown} {
    padding-bottom: 3.2rem;
    width: 100%;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    overflow: initial;
  }
`;

export const Tile = styled.div<{
  isFaded: boolean;
  isFadingIn: boolean;
  margin: number;
  slideLeft: boolean;
  slideRight: boolean;
  width: number;
}>`
  ${props =>
    props.slideRight &&
    css`
      animation-duration: ${ANIMATION_DURATION};
      animation-fill-mode: forwards;
      animation-name: ${slideRight};
    `}
  ${props => props.theme.mediaQueries.mediumDown} {
    ${props =>
      props.slideRight &&
      css`
        animation-duration: ${ANIMATION_DURATION};
        animation-fill-mode: forwards;
        animation-name: ${mobileSlideRight};
      `}
  }

  ${props =>
    props.slideLeft &&
    css`
      animation-duration: ${ANIMATION_DURATION};
      animation-fill-mode: forwards;
      animation-name: ${slideLeft};
    `}

  ${props => props.theme.mediaQueries.mediumDown} {
    ${props =>
      props.slideLeft &&
      css`
        animation-duration: ${ANIMATION_DURATION};
        animation-fill-mode: forwards;
        animation-name: ${mobileSlideLeft};
      `}
  }

  ${props =>
    props.isFadingIn &&
    css`
      animation-name: ${FADE_IN};
      animation-duration: ${ANIMATION_DURATION};
    `}

  width: ${props => props.width}px;
  opacity: ${props => (props.isFaded ? props.theme.opacities.overlay : props.theme.opacities.full)};
  transition: opacity 0.25s ease-in-out;
  background: ${props => props.theme.colors.capsuleBlue50};

  &:not(:last-of-type) {
    margin-right: ${props => props.margin}px;
  }
`;

export const ReviewerImage = styled.img<{ hasRightTopPosition?: boolean; translateDown?: boolean }>`
  width: ${props => props.width}px;
  height: 256px;
  object-fit: cover;
  object-position: ${props => (props.hasRightTopPosition ? 'right top' : '')};
  object-position: ${props => (props.translateDown ? '20% 20%' : '')};
`;

export const ReviewContainer = styled.div`
  width: 100%;
  height: 19.2rem;
  font-family: ${props => props.theme.fonts.circular};
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.fonts.circular};
  font-weight: ${props => props.theme.fontWeights.book};
  font-size: ${props => props.theme.fontSizes.headerThree};
  line-height: ${props => props.theme.lineHeights.headerThree};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.space.s5};
`;

export const Reviewer = styled.div`
  font-weight: ${props => props.theme.fontWeights.semiBold};
  font-size: ${props => props.theme.fontSizes.small};
  line-height: ${props => props.theme.lineHeights.small};
  color: ${props => props.theme.colors.capsuleGreen50};
  margin: 1rem 3.2rem 0 0;
`;

export const ArrowsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 2.5rem;
`;

export const ArrowButton = styled.button`
  background: none;
  border: none;
  padding: ${props => props.theme.space.none};
  outline: inherit;
  margin: ${props => props.theme.space.none} 8.1rem;
  cursor: pointer;

  ${props => props.theme.mediaQueries.mediumDown} {
    display: none;
  }
`;

export const ArrowIcon = styled(CapsuleIcon)`
  svg {
    height: 4rem;
    width: 4.4rem;
  }
`;

export const Circle = styled.button<{ isFaded: boolean; isFirst: boolean }>`
  padding: ${props => props.theme.space.none};
  outline: inherit;
  margin-left: ${props => (props.isFirst ? '' : props.theme.space.s3)};
  border: none;
  border-radius: 50%;
  background: ${props => (props.isFaded ? props.theme.colors.capsuleGray10 : props.theme.colors.capsuleBlue50)};
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;

  ${props => props.theme.mediaQueries.medium} {
    display: none;
  }
`;
