import styled from 'styled-components';

import { Typography } from '../../capsule-elements/Typography';

export const ContentWrapper = styled.div`
  margin: ${props => props.theme.space.s9} auto;

  ${props => props.theme.mediaQueries.mediumDown} {
    margin-bottom: ${props => props.theme.space.s8};
    margin-top: ${props => props.theme.space.s8};
  }

  ${props => props.theme.mediaQueries.smallDown} {
    margin: ${props => props.theme.space.s7} auto;
  }
`;

export const SectionTitle = styled(Typography)`
  ${props => props.theme.mediaQueries.medium} {
    margin-bottom: 6rem !important;
  }
`;

export const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${props => props.theme.mediaQueries.medium} {
    flex-direction: row;
  }
`;

export const FlexItem = styled.div`
  flex: 0 0 30%;

  &:not(:first-child):not(:last-child) {
    margin: 3.2rem 0;

    ${props => props.theme.mediaQueries.medium} {
      margin: 0 2.4rem;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;

  ${props => props.theme.mediaQueries.small} {
    max-width: 361px;
    max-height: 364px;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 1.2rem;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  ${props => props.theme.mediaQueries.medium} {
    display: block;
    margin-bottom: 3.2rem;
  }
`;

export const TextWrapper = styled.div`
  ${props => props.theme.mediaQueries.mediumDown} {
    max-width: 361px;
    margin: 0 auto;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    max-width: none;
    margin: 0 !important;
  }
`;

export const ContentItem = styled.div`
  padding: 0;
`;
