import type { FocusEvent, MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import { ScreenReaderOnly } from '@capsule/consumer-design-system';

import qs from 'query-string';
import { useInView } from 'react-intersection-observer';

import { checkZipCodeEligibility } from '../../capsule-client';
import { Input } from '../../capsule-elements/Input';
import { EVENT, track } from '../../capsule-tracking/analytics';
import { extractDigits } from '../../utils';

import {
  ArrowSVG,
  CheckZipButton,
  CheckZipButtonWrapper,
  ZipCodeEligibilityWrapper,
  ZipCodeInputContainer,
  ZipCodeSubLabel,
} from './styles';

enum STEP_NAME {
  ELIGIBILITY_CONFIRMATION = 'ELIGIBILITY_CONFIRMATION',
  INELIGIBLE_STEP = 'INELIGIBLE_STEP',
}

interface ZipCodeResponseProps {
  data: {
    isDeliverable: boolean;
    latitude?: number;
    longitude?: number;
  };
}

const zipCodeEligibilityFormId = 'zcefid';
export const ZipCodeEligibility = () => {
  const [isDeliverable, setIsDeliverable] = useState(false);
  const [isCheckingZip, setIsCheckingZip] = useState(false);
  const [zipCode, setZipCode] = useState('');

  const { inView, ref: intersectionObserverRef } = useInView({ threshold: 0.25 });

  useEffect(() => {
    if (inView) {
      track(EVENT.viewZipcodeSection);
    }
  }, [inView]);

  const checkZipCode = async (e: FocusEvent<HTMLInputElement>) => {
    setIsCheckingZip(true);
    e.preventDefault();
    const zipCode = (e?.target as HTMLInputElement)?.value;

    // TODO: We can create a number input that handles this implicitly
    // Don't allow non-integer inputs in zipcode
    const filteredZipCode = extractDigits(zipCode);
    setZipCode(filteredZipCode);

    if (filteredZipCode?.length < 5 && isDeliverable) {
      // If user removes digits, reset isDeliverable
      setIsDeliverable(false);
    }

    if (filteredZipCode.length === 5) {
      try {
        const resp: ZipCodeResponseProps = await checkZipCodeEligibility(filteredZipCode);
        setIsDeliverable(resp.data?.isDeliverable);
        track(EVENT.deliveryAreaEligibilityZipcodeSuccessful, {
          zipcode: filteredZipCode,
          pagepath: window?.location.pathname,
        });
      } catch (e) {
        track(EVENT.deliveryAreaEligibilityZipcodeFailed, {
          zipcode: filteredZipCode,
          pagepath: window?.location.pathname,
        });
      } finally {
        setIsCheckingZip(false);
      }
    }
    setIsCheckingZip(false);
  };

  const handleOnFocus = () => {
    track(EVENT.fieldFocused, { fieldname: 'zipCode', pagepath: window?.location.pathname });
  };

  const handleSubmit = (e: MouseEvent, zipCode: string, isDeliverable: boolean, isCheckingZip: boolean) => {
    e.preventDefault();
    const previousParams = window ? qs.parse(window.location.search) : {};
    const query = {
      ...previousParams,
      zipCode,
      isDeliverable,
      step: '',
    };

    if (zipCode?.length !== 5 || isCheckingZip) return;

    if (isDeliverable) {
      query.step = STEP_NAME.ELIGIBILITY_CONFIRMATION.toLowerCase();
    } else {
      query.step = STEP_NAME.INELIGIBLE_STEP.toLowerCase();
    }
    track(EVENT.beginOnboarding, { pagepath: window?.location?.pathname, source: 'Homepage Zipcode Checker' });
    window.location.assign(`/get-started/?${qs.stringify(query)}`);
  };

  return (
    <ZipCodeEligibilityWrapper ref={intersectionObserverRef} id={zipCodeEligibilityFormId}>
      <ZipCodeInputContainer>
        <Input
          type="text"
          label="Enter home or work zip code"
          id="zipCode"
          name="zipCode"
          onChange={checkZipCode}
          onFocus={handleOnFocus}
          isSuccess={isDeliverable}
          value={zipCode}
          isLabelAnimated
          variant="default"
          maxLength={5}
        />
        <ZipCodeSubLabel>{isDeliverable ? "You're in our delivery area" : null}</ZipCodeSubLabel>
      </ZipCodeInputContainer>
      <CheckZipButtonWrapper>
        {/* TODO: use IconButton when it's made */}
        <CheckZipButton
          type="submit"
          form={zipCodeEligibilityFormId}
          onClick={(e: MouseEvent) => handleSubmit(e, zipCode, isDeliverable, isCheckingZip)}
        >
          <ScreenReaderOnly>Submit</ScreenReaderOnly>
          <ArrowSVG iconName="RightArrow" />
        </CheckZipButton>
      </CheckZipButtonWrapper>
    </ZipCodeEligibilityWrapper>
  );
};
