import { useEffect, useState } from 'react';

import { Button } from '@capsule/consumer-design-system';

import { useMediaQuery } from '@react-hookz/web';
import throttle from 'lodash/throttle';

import { onGetStartedClickHandleRedirect } from 'src/landing-pages/common/handlers/ClickHandlers';
import { Breakpoint } from 'src/styles/theme';

import { FixedNav } from './styles';

export const StickyButton = () => {
  const [mobileFooterOpacity, setMobileFooterOpacity] = useState(0);

  const smDown = useMediaQuery(Breakpoint.smallDown);

  useEffect(() => {
    const getScrollDepth = throttle(() => {
      const scroll = window.scrollY || window.pageYOffset;
      const stickyAnchor = document.querySelector('#stickyButtonAnchor') as HTMLDivElement;
      const anchorOffSet = stickyAnchor?.offsetTop;

      if (!stickyAnchor) {
        return;
      }

      const mobileFooterOpacityConditional = anchorOffSet >= scroll;

      if (mobileFooterOpacityConditional) {
        setMobileFooterOpacity(0);
      } else {
        setMobileFooterOpacity(1);
      }
    }, 100);

    getScrollDepth();
    window.addEventListener('scroll', getScrollDepth);

    return () => {
      window.removeEventListener('scroll', getScrollDepth);
    };
  }, [setMobileFooterOpacity, smDown]);

  return (
    <>
      <div id="stickyButtonAnchor" />
      <FixedNav opacity={mobileFooterOpacity}>
        <Button
          variant="contained-primary-lg"
          isDisabled={mobileFooterOpacity === 0}
          isFullWidth
          onClick={() => onGetStartedClickHandleRedirect('Mobile Sticky CTA')}
        >
          Get Started
        </Button>
      </FixedNav>
    </>
  );
};
