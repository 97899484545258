import { UnstyledButton } from '@capsule/consumer-design-system';

import styled from 'styled-components';

import { Typography } from '../../capsule-elements/Typography';
import { CapsuleIcon } from '../../icons/CapsuleIcon';

export const ZipCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.mediaQueries.mediumDown} {
    display: block;
  }
`;

export const ZipCodeEligibilityWrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
`;

export const ZipCodeText = styled.div`
  flex: 1;

  margin-top: ${props => props.theme.space.s9};
  margin-bottom: ${props => props.theme.space.s9};
  margin-right: ${props => props.theme.space.s5};

  ${props => props.theme.mediaQueries.mediumDown} {
    margin-top: ${props => props.theme.space.s8};
    margin-bottom: ${props => props.theme.space.s8};
  }
`;

export const ZipCodeLabel = styled(Typography)`
  ${props => props.theme.mediaQueries.mediumDown} {
    font-size: 2.8rem !important;
    margin-top: 7.2rem !important;
    line-height: 3.2rem !important;
  }
`;

export const ZipCodeInputContainer = styled.div`
  flex: 1;
`;

export const ZipCodeSubLabel = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  font-family: ${props => props.theme.fonts.circular};
  margin-top: ${props => props.theme.space.s1};
  height: 1.4rem; /* need a fixed height for alignment when no text */
  color: ${props => props.theme.colors.capsuleGray50};
`;

export const CheckZipButtonWrapper = styled.div`
  margin-left: ${props => props.theme.space.s5};
  position: relative;
  bottom: 1px;

  ${props => props.theme.mediaQueries.smallDown} {
    bottom: 0.1rem;
  }
`;

export const CheckZipButton = styled(UnstyledButton)`
  border-radius: ${({ theme }) => theme.radii.small};
  width: 4.8rem;
  height: 4.8rem;
  margin-bottom: 1.7rem; /* specific value to align with the input */
  background-color: ${props => props.theme.colors.capsuleGreen60};

  &:hover {
    background-color: ${props => props.theme.colors.capsuleGreen80};
  }
`;

export const ArrowSVG = styled(CapsuleIcon)`
  display: flex;
  svg {
    width: 32px;
    height: 32px;
    right: 4px;
  }
`;
