import { SectionContainer } from '../../capsule-components/Sections/SectionContainer';

import { ZipCodeContainer, ZipCodeLabel, ZipCodeText } from './styles';
import { ZipCodeEligibility } from './ZipCodeEligibility';

export const ZipCodeSection = () => {
  return (
    <SectionContainer>
      <ZipCodeContainer>
        <ZipCodeText>
          <ZipCodeLabel variant="displayXL" smallDownVariant="h1">
            Check if we deliver to you
          </ZipCodeLabel>
        </ZipCodeText>
        <ZipCodeEligibility />
      </ZipCodeContainer>
    </SectionContainer>
  );
};
