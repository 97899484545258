import { SectionContainer } from './SectionContainer';
import { SectionBorder } from './styles';

const SectionBorderDivider = () => {
  return (
    <SectionContainer>
      <SectionBorder />
    </SectionContainer>
  );
};

export { SectionBorderDivider };
