import styled from 'styled-components';
import InlineSVG from 'svg-inline-react';

import { Typography } from '../../capsule-elements/Typography';

export const ContentWrapper = styled.div`
  margin: ${props => props.theme.space.s9} auto ${props => props.theme.space.s9};

  ${props => props.theme.mediaQueries.smallDown} {
    margin: ${props => props.theme.space.s8} auto ${props => props.theme.space.s8};
  }
`;

export const TitleContent = styled.div`
  margin-bottom: 4.8rem;
  width: 100%;
  ${props => props.theme.mediaQueries.medium} {
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.div`
  text-align: center;

  ${props => props.theme.mediaQueries.small} {
    text-align: left;
  }
`;

export const TitleLineTwo = styled.span`
  display: inline-block;
  ${props => props.theme.mediaQueries.smallDown} {
    display: block;
  }
`;

export const RowContainer = styled.div`
  margin: 0 auto;
  max-width: 93.8rem;
`;

export const StepTitle = styled(Typography)`
  text-align: center;
  margin: 0 auto 1.2rem !important;

  ${props => props.theme.mediaQueries.medium} {
    max-width: 255px;
  }
`;

export const InlineBlockContent = styled.span`
  display: inline-block;
`;

export const StepDescription = styled(Typography)`
  max-width: 255px;
  margin: 0 auto !important;
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IconSVG = styled(InlineSVG)`
  svg {
    width: 80px;
    height: 80px;
  }

  ${props => props.theme.mediaQueries.small} {
    svg {
      width: 96px;
      height: 96px;
    }
  }

  margin-bottom: 4rem;
`;

export const RowBreak = styled.div`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.capsuleGreen55};
  margin: 3.2rem 0;
`;
