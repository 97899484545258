import styled, { css } from 'styled-components';
import InlineSVG from 'svg-inline-react';

import { Typography } from '../../capsule-elements/Typography';

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const AsSeenInContent = styled.div`
  margin: 7rem 0 ${props => props.theme.space.s7} 0;
`;

export const SmallCallout = styled(Typography)`
  margin-bottom: 0.8rem !important;
  text-align: center;
`;

export const Underline = styled.div`
  width: 3.2rem;
  height: 1px;
  background-color: #74a6a2;
  margin: 0 auto;
`;

export const QuoteContent = styled.div`
  margin: 0 auto 6.3rem;
  max-width: 32.7rem;
  line-height: ${props => props.theme.lineHeights.displayXXL};

  > *:first-child {
    margin-bottom: 0;
  }

  ${props => props.theme.mediaQueries.small} {
    line-height: ${props => props.theme.lineHeights.headerOne};
    max-width: 85rem;
    text-align: center;
  }
`;

export const Quote = styled(Typography)`
  margin: 0 auto;

  ${props => props.theme.mediaQueries.small} {
    max-width: 80rem;
  }

  ${props => props.theme.mediaQueries.smallDown} {
    max-width: 27.5rem;
    font-size: ${props => props.theme.fontSizes.headerTwo} !important;
  }
`;

export const QuoteAuthor = styled(Typography)`
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.book};
`;

export const PressIconGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: 0 auto 9.6rem;

  ${props => props.theme.mediaQueries.mediumDown} {
    i:nth-child(-n + 3) {
      margin-bottom: 3.6rem;
    }
  }
`;

const PressIcon = css`
  flex: 0 0 33.333333%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;

  ${props => props.theme.mediaQueries.medium} {
    flex: 1;
  }
`;

export const GMAPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 57px !important;
    height: 57px !important;
  }
`;

export const CNBCPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 41px !important;
    height: 32px !important;
  }
`;

export const ForbesPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 63px !important;
    height: 16px !important;
  }
`;

export const WSJPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 104px !important;
    height: 8px !important;
  }
`;

export const CNNPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 43px !important;
    height: 22px !important;
  }
`;

export const NYTPressIcon = styled(InlineSVG)`
  ${PressIcon}

  svg {
    width: 104px !important;
    height: 14px !important;
  }
`;

export const YellowPill = styled(InlineSVG)`
  svg {
    transform: rotate(45deg);
    width: 50px;
    height: 129px;
    position: absolute;
    left: -30px;
    top: 5px;

    * {
      fill: ${props => props.theme.colors.capsuleYellow};
    }
  }

  ${props => props.theme.mediaQueries.small} {
    svg {
      width: 205px;
      height: 519px;
      left: -80px;
      top: -20px;
      z-index: 0 !important;
    }
  }

  @media (min-width: 1900px) {
    left: -300px;
  }

  @media (min-width: 2500px) {
    left: -600px;
  }
`;
