import { useCallback, useEffect, useRef, useState } from 'react';

import { ScreenReaderOnly } from '@capsule/consumer-design-system';

import { useMediaQuery } from '@react-hookz/web';
import debounce from 'lodash/debounce';
import range from 'lodash/range';
import times from 'lodash/times';
import { useInView } from 'react-intersection-observer';

import { LANDING_PAGE_CAROUSEL } from 'src/consts/testIDs';
import { SectionContainer } from 'src/landing-pages/capsule-components/Sections/SectionContainer';
import { Typography } from 'src/landing-pages/capsule-elements/Typography';
import { EVENT, track } from 'src/landing-pages/capsule-tracking/analytics';
import { Breakpoint } from 'src/styles/theme';

import {
  ArrowButton,
  ArrowIcon,
  ArrowsContainer,
  Circle,
  ContentWrapper,
  GoldStar,
  PaddingContainer,
  QuoteContainer,
  Rating,
  RatingContainer,
  RatingSources,
  ReviewContainer,
  Reviewer,
  ReviewerImage,
  ReviewSectionContainer,
  StarContainer,
  Tile,
  TilesContainer,
} from './styles';

const reviews = [
  {
    review: <>Capsule is probably the best designed, most life-transformative product that I have used.&rdquo;</>,
    reviewer: <>&#8212;&nbsp;Joseph</>,
    imageString: '/public/joseph-scaled.webp',
    alt: 'Capsule Customer Joseph',
  },
  {
    review: <>If you can text, you can use Capsule.&rdquo;</>,
    reviewer: <>&#8212;&nbsp;Kerstin</>,
    imageString: '/public/kerstin-scaled.webp',
    alt: 'Capsule Customer Kerstin',
  },
  {
    review: (
      <>
        There's ton of peace of mind knowing that you can text somebody and you'll get a response right away about the
        medication you're taking.&rdquo;
      </>
    ),
    reviewer: <>&#8212;&nbsp;Nancy</>,
    imageString: '/public/nancy-v2-scaled.webp',
    hasRightTopPosition: true,
    alt: 'Capsule Customer Nancy',
  },
  {
    review: <>My only regret is not having tried Capsule sooner.&rdquo;</>,
    reviewer: <>&#8212;&nbsp;Andrew</>,
    imageString: '/public/andrew-scaled.webp',
    alt: 'Capsule Customer Andrew',
  },
  {
    review: (
      <>
        Capsule 100% did everything for me. They took my prescription from my doctor, found the best price, and
        delivered my prescription to my doorstep.&rdquo;
      </>
    ),
    reviewer: <>&#8212;&nbsp;Shwetabh</>,
    imageString: '/public/shwetabh-scaled.webp',
    translateDown: true,
    alt: 'Capsule Customer Shwetabh',
  },
  {
    review: (
      <>
        Capsule checks in with me, reminds me when I need prescriptions, and makes it easy to get them delivered.&rdquo;
      </>
    ),
    reviewer: <>&#8212;&nbsp;Olivia</>,
    imageString: '/public/olivia-scaled.webp',
    hasRightTopPosition: true,
    alt: 'Capsule Customer Olivia',
  },
];

const ANIMATION_DURATION = 750;

const isInViewport = (idx: number) => {
  const FADE_BUFFER = 20;
  const element = document.getElementById(`tile ${idx}`);
  const rect = element?.getBoundingClientRect() || { left: 0, right: 0 };

  const slider = document.getElementById('tiles-container');
  const sliderRect = slider?.getBoundingClientRect() || { left: 0, right: 0 };

  return (
    rect.left + FADE_BUFFER >= sliderRect.left &&
    rect.right <= sliderRect.right + FADE_BUFFER &&
    rect.right <= window.innerWidth + FADE_BUFFER
  );
};

export const ReviewSlider = () => {
  const md_down = useMediaQuery(Breakpoint.mediumDown);

  const [tilesFadeStatus, setTilesFadeStatus] = useState<{ [key: number]: boolean }>({});
  const [firstTileIdx, setFirstTileIdx] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [clickX, setClickX] = useState(0);
  const [clickY, setClickY] = useState(0);
  const [slideRight, setSlideRight] = useState(false);
  const [slideLeft, setSlideLeft] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(false);

  const sliderRef = useRef<HTMLDivElement>(null);

  const { inView, ref } = useInView({ threshold: 0.25 });

  useEffect(() => {
    if (inView) {
      track(EVENT.viewReviewsSection);
    }
  }, [inView]);

  const numReviews = reviews.length;
  const displayIndices = range(numReviews).map(key => (firstTileIdx + key) % numReviews);

  const tilesMargin = md_down ? 8 : 24;
  const tileWidth = 327;

  const DEBOUNCE_WAIT = 100;
  const triggerPercent = 0.08;

  const handleLeftClick = useCallback(() => {
    setButtonClicked(true);
    setSlideRight(true);

    track(EVENT.scrollReviews);

    setTimeout(() => {
      const nextFirstTile = (firstTileIdx - 1 + numReviews) % numReviews;
      setFirstTileIdx(nextFirstTile);
      setSlideRight(false);
    }, ANIMATION_DURATION);
  }, [firstTileIdx, numReviews]);

  const handleRightClick = useCallback(() => {
    setButtonClicked(true);
    setSlideLeft(true);

    track(EVENT.scrollReviews);

    setTimeout(() => {
      setSlideLeft(false);
      const nextFirstTile = (firstTileIdx + 1) % numReviews;
      setFirstTileIdx(nextFirstTile);
    }, ANIMATION_DURATION);
  }, [firstTileIdx, numReviews]);

  const handleCircleButtonClick = (idx: number) => () => {
    setButtonClicked(true);
    setFirstTileIdx(idx);
    setIsFadingIn(true);

    setTimeout(() => {
      setIsFadingIn(false);
    }, ANIMATION_DURATION);
  };

  const handleTileFade = useCallback(() => {
    // Since 0 is falsy, the additional buttonClicked conditional is needed.
    // We do not want handleTileFade to run upon a first render, when the firstTileIndex is 0.
    if (firstTileIdx || buttonClicked) {
      const nextTilesFadeStatus: { [key: number]: boolean } = {};
      times(numReviews, idx => {
        nextTilesFadeStatus[idx] = !isInViewport(idx);
      });

      setTilesFadeStatus(nextTilesFadeStatus);
    }
  }, [firstTileIdx, buttonClicked, numReviews]);

  const sliderRefCurrent = sliderRef?.current;

  // Swiping effects
  useEffect(() => {
    let isMouseDown = false;
    const tilesContainer = document.getElementById('tiles-container');

    const handleTouchStart = (evt: TouchEvent) => {
      setClickX(evt.touches[0].clientX);
      setClickY(evt.touches[0].clientY);
    };

    const handleMouseDown = (evt: MouseEvent) => {
      setClickX(evt.clientX);
      setClickY(evt.clientY);
      isMouseDown = true;
    };

    const handleTouchEnd = (evt: TouchEvent) => {
      const deltaX = evt.changedTouches[0].clientX - clickX;
      const deltaY = evt.changedTouches[0].clientY - clickY;
      const distMoved = Math.abs(Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY);
      const activePct = tilesContainer ? distMoved / tilesContainer.offsetWidth : 0;

      if (activePct > triggerPercent) {
        // If deltaY is greater we are scrolling, if deltaX is greater we are swiping
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (deltaX < 0) {
            handleRightClick();
          } else {
            handleLeftClick();
          }
        }
      }
    };

    const handleMouseUp = (evt: MouseEvent) => {
      if (isMouseDown === false) return;
      const deltaX = evt.clientX - clickX;
      const deltaY = evt.clientY - clickY;
      const distMoved = Math.abs(Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY);
      const activePct = tilesContainer ? distMoved / tilesContainer.offsetWidth : 0;

      if (activePct > triggerPercent) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (deltaX < 0) {
            handleRightClick();
          } else {
            handleLeftClick();
          }
        }
      }
    };

    sliderRefCurrent?.addEventListener('touchstart', handleTouchStart);
    sliderRefCurrent?.addEventListener('touchend', handleTouchEnd);
    sliderRefCurrent?.addEventListener('mousedown', handleMouseDown);
    sliderRefCurrent?.addEventListener('mouseup', handleMouseUp);

    return () => {
      sliderRefCurrent?.removeEventListener('touchstart', handleTouchStart);
      sliderRefCurrent?.removeEventListener('touchend', handleTouchEnd);
      sliderRefCurrent?.removeEventListener('mousedown', handleMouseDown);
      sliderRefCurrent?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [clickX, clickY, handleRightClick, handleLeftClick, sliderRefCurrent]);

  useEffect(() => {
    window.addEventListener('resize', debounce(handleTileFade, DEBOUNCE_WAIT));
    return () => {
      window.removeEventListener('resize', debounce(handleTileFade, DEBOUNCE_WAIT));
    };
  }, [handleTileFade]);

  // For small screens, only three review tiles are needed, the "first" tile being the only fully visible one, set between two others.
  const resolvedDisplayIndices = md_down ? displayIndices.slice(0, 3) : displayIndices;

  useEffect(() => {
    handleTileFade();
  }, [firstTileIdx, handleTileFade]);

  return (
    <PaddingContainer>
      <SectionContainer borderBottom>
        <ReviewSectionContainer>
          <ContentWrapper ref={ref}>
            <Typography variant="displayXL" smallDownVariant="h1">
              Everybody loves Capsule
            </Typography>
          </ContentWrapper>
          <RatingContainer>
            <Rating>4.9</Rating>
            <StarContainer>
              {times(5, idx => {
                return <GoldStar iconName="GoldStar" key={`star_${idx}`} />;
              })}
            </StarContainer>
            <RatingSources>Google Places and Apple App Store</RatingSources>
          </RatingContainer>

          <TilesContainer id={'tiles-container'} data-testid={LANDING_PAGE_CAROUSEL} ref={sliderRef}>
            {resolvedDisplayIndices.map(idx => {
              const reviewEntry = reviews[idx];
              return (
                <Tile
                  margin={tilesMargin}
                  width={tileWidth}
                  isFaded={slideLeft || slideRight ? false : tilesFadeStatus[idx]}
                  slideRight={slideRight}
                  slideLeft={slideLeft}
                  isFadingIn={isFadingIn}
                  id={`tile ${idx}`}
                  key={`tile_${idx}`}
                >
                  <ReviewerImage
                    src={reviewEntry.imageString}
                    width={tileWidth}
                    hasRightTopPosition={reviewEntry.hasRightTopPosition}
                    translateDown={reviewEntry.translateDown}
                    alt={reviewEntry.alt}
                  />
                  <QuoteContainer>
                    &ldquo;
                    <ReviewContainer>
                      <>{reviewEntry.review}</>
                      <Reviewer>{reviewEntry.reviewer}</Reviewer>
                    </ReviewContainer>
                  </QuoteContainer>
                </Tile>
              );
            })}
          </TilesContainer>

          <ArrowsContainer>
            <ArrowButton onClick={handleLeftClick} disabled={slideLeft || slideRight}>
              <ScreenReaderOnly>Slide Carousel Left</ScreenReaderOnly>
              <ArrowIcon iconName="GreenBackArrow" />
            </ArrowButton>

            {times(numReviews, idx => {
              return (
                <Circle
                  isFaded={firstTileIdx !== idx}
                  onClick={handleCircleButtonClick(idx)}
                  isFirst={idx === 0}
                  key={`slider_circle_${idx}`}
                />
              );
            })}

            <ArrowButton onClick={handleRightClick} disabled={slideLeft || slideRight}>
              <ScreenReaderOnly>Slide Carousel Right</ScreenReaderOnly>
              <ArrowIcon iconName="GreenForwardArrow" />
            </ArrowButton>
          </ArrowsContainer>
        </ReviewSectionContainer>
      </SectionContainer>
    </PaddingContainer>
  );
};
