import styled, { css } from 'styled-components';

import { CapsuleIcon } from '../../icons/CapsuleIcon';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: ${props => props.theme.space.s7};
  position: relative;
`;

export const LabelContainer = styled.div<{ isLabelAnimated: boolean; showPlaceholder: boolean; variant: string }>`
  transform: ${props => {
    return props.showPlaceholder && props.isLabelAnimated ? 'none' : 'scale(1.2) translateX(0%) translateY(110%)';
  }};
  transform-origin: 0% 50% 0;
  transition: ${props => {
    return props.isLabelAnimated ? 'transform 250ms ease' : 'none';
  }};
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.book};
  ${props =>
    props.variant === 'default' &&
    css`
      color: ${props => props.theme.colors.capsuleGreen60};
    `}
  ${props =>
    props.variant === 'dark' &&
    css`
      color: ${props => props.theme.colors.capsuleGreen50};
    `}
`;

export const StyledLabel = styled.label`
  cursor: text;
`;

export const StyledInput = styled.input<{ isLabelAnimated: boolean; variant: string }>`
  width: 100%;
  position: relative;
  clear: both;
  margin: 0 0 ${props => props.theme.space['0.24']};
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0;
  border-bottom-width: 0.1rem;
  outline: 0;
  border-radius: 0;
  transition: color 0.1s ease, border-color 0.1s ease;
  margin-bottom: 0.1rem; /* offset for 2px border on active */
  padding: ${props => props.theme.space.s2} ${props => props.theme.space.none};
  font-family: ${props => props.theme.fonts.circular};
  font-size: ${props => props.theme.fontSizes.body};
  font-weight: ${props => props.theme.fontWeights.book};

  ${props =>
    props.variant === 'default' &&
    css`
      color: ${props => props.theme.colors.capsuleBlue50};
      border-bottom: solid 0.1rem ${props => props.theme.colors.capsuleGreen60};
      box-shadow: 0 0 0 30px ${props => props.theme.colors.white} inset !important; /* overwrites chrome highlighting */
      &:-webkit-autofill {
        -webkit-text-fill-color: ${props => props.theme.colors.capsuleBlue50} !important;
      }
    `}
  ${props =>
    props.variant === 'dark' &&
    css`
      color: ${props => props.theme.colors.capsuleGreen50};
      border-bottom: solid 0.1rem ${props => props.theme.colors.capsuleGreen50};
      box-shadow: 0 0 0 30px ${props => props.theme.colors.capsuleBlue50} inset !important; /* overwrites chrome highlighting */
      /* stylelint-disable no-duplicate-selectors */
      &:-webkit-autofill {
        -webkit-text-fill-color: ${props => props.theme.colors.capsuleGreen50} !important;
      }
    `}


  &:focus {
    border-bottom-width: 0.2rem;
    margin-bottom: 0;
    ${props =>
      !props.isLabelAnimated &&
      css`
        + div {
          display: none;
        }
      `}
  }

  ${props =>
    !props.isLabelAnimated &&
    css`
      &:not([value='']) {
        + div {
          display: none !important;
        }
      }
    `}
`;

export const SuccessIcon = styled(CapsuleIcon)<{ variant: string }>`
  svg {
    ${props =>
      props.variant === 'default' &&
      css`
        fill: ${props => props.theme.colors.capsuleGreen60};
      `}
    ${props =>
      props.variant === 'dark' &&
      css`
        fill: ${props => props.theme.colors.capsuleGreen50};
      `}
  }
  position: absolute;
  right: 0;
  bottom: 0.7rem;
`;
