import type { FocusEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import { InputContainer, LabelContainer, StyledInput, StyledLabel, SuccessIcon } from './styles';

export interface InputProps {
  id?: string;
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  error?: string;
  onChange: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  maxLength: number;
  type: string;
  isSuccess: boolean;
  isLabelAnimated: boolean;
  variant: 'default' | 'dark';
}

export const Input = (props: InputProps) => {
  const {
    id,
    label,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    isDisabled,
    maxLength,
    isSuccess,
    isLabelAnimated = true,
    variant = 'default',
  } = props;
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setShowPlaceholder(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setShowPlaceholder(!!e.target?.value);
    if (onBlur) {
      onBlur();
    }
  };

  const focusInput = () => {
    // the label blocks the input click so we focus input on label click
    ref.current?.focus();
  };

  useEffect(() => {
    // if there is an initial value, show placeholder animation
    if (ref.current?.getAttribute('value')) setShowPlaceholder(true);
  }, [ref]);

  return (
    <InputContainer>
      <StyledInput
        {...{
          id,
          name,
          placeholder,
          onChange,
          type: 'text',
          disabled: isDisabled,
          onFocus: handleFocus,
          onBlur: handleBlur,
          ref,
          value,
          isLabelAnimated,
          variant,
          maxLength,
        }}
      />
      <LabelContainer
        showPlaceholder={showPlaceholder}
        isLabelAnimated={isLabelAnimated}
        onClick={focusInput}
        variant={variant}
      >
        <StyledLabel htmlFor={id || name}>{label}</StyledLabel>
      </LabelContainer>
      {isSuccess && <SuccessIcon iconName={'CircleCheck'} variant={variant} />}
    </InputContainer>
  );
};
